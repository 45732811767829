import type {
    TravelFormDataV1_jsonld_page_read,
    TravelFormDestinationResult,
    TravelFormFilterSelectionType,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import debounce from 'lodash.debounce';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import useDynamicPageComponentByType from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-type';

export default function useTravelFormDestinationOverlay({
    destinationRequestTarget,
    queryConfig,
    selectedItems,
    selectionType,
}: {
    destinationRequestTarget: null | string;
    queryConfig?: { enabled?: boolean };
    selectedItems: TravelFormDestinationResult[] | undefined;
    selectionType: TravelFormFilterSelectionType | undefined;
}) {
    const initialDestinationFilter =
        useDynamicPageComponentByType<TravelFormDataV1_jsonld_page_read>('TravelFormDataV1')?.attributes?.filters
            .destination;
    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);
    const [defaultDestination, setDefaultDestination] = React.useState<TravelFormDestinationResult | null>(null);
    const [selectedDestinations, setSelectedDestinations] = React.useState<TravelFormDestinationResult[]>(
        selectedItems ?? [],
    );
    const [targetComponentIri, setTargetComponentIri] = React.useState<null | string>(destinationRequestTarget);
    const [searchQuery, setSearchQuery] = React.useState('');
    const { data, isLoading } = useComponentDataByIri<TravelFormDataV1_jsonld_page_read>(targetComponentIri, {
        config: queryConfig,
        queryName: 'travelFormDestinationOverlay',
    });

    React.useEffect(() => {
        setSelectedDestinations(selectedItems ?? []);
    }, [selectedItems]);

    const isReady = !!destinationRequestTarget && !!targetComponentIri && !!queryConfig?.enabled;

    const setDebouncedDestinationSearchQuery = React.useRef(
        debounce((query: string, targetIri: string) => {
            if (query.length === 0) {
                resetSearch();
                return;
            }

            const URL = updateComponentIriParam('userQuery', targetIri, { query: query.toString() });
            setTargetComponentIri(URL.pathname + URL.search);
            setSearchQuery(query);
        }, 300),
    ).current;

    const searchDestination = (searchValue: string, targetIri: string) => {
        setDebouncedDestinationSearchQuery(searchValue, targetIri);
    };

    const handleDefaultSelection = (nextSelectedDestinations: TravelFormDestinationResult[]) => {
        if (nextSelectedDestinations.length === 0 && defaultDestination) {
            return [defaultDestination];
        }
        return nextSelectedDestinations;
    };

    const resetSearch = () => {
        setTargetComponentIri(destinationRequestTarget);
        setSearchQuery('');
    };

    const resetOverlay = () => {
        if (!selectedItems) {
            return;
        }
        setSelectedDestinations(selectedItems);
        resetSearch();
    };

    const onDestinationResultClick = (
        destination: TravelFormDestinationResult,
        event?: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>,
        onDefaultClick?: (destinationsListString: null | string) => void,
    ) => {
        event?.stopPropagation();

        setWasActivelyUsed(true);

        if (selectionType === 'single') {
            const listOfDestinations = getDestinationListString([destination]);
            setSelectedDestinations([destination]);
            // not really a defaultClick but its handled like one
            if (listOfDestinations.length === 0) {
                return onDefaultClick?.(null);
            }
            return onDefaultClick?.(listOfDestinations);
        }

        if (destination.isDefault && defaultDestination) {
            setSelectedDestinations([defaultDestination]);
            return onDefaultClick?.(null);
        }

        if (selectedDestinations.some((destination) => destination.isDefault) && defaultDestination) {
            removeDestination(defaultDestination);
        }

        if (selectedDestinations.some((selectedDestination) => selectedDestination.name === destination.name)) {
            removeDestination(destination);
        } else {
            addDestination(destination);
        }
    };

    const addDestination = (destination: TravelFormDestinationResult) => {
        setSelectedDestinations((prev) => {
            const nextSelectedDestinations = prev.map((destination) => destination.name).includes(destination.name)
                ? prev
                : [...prev, destination].filter((destination) => !destination.isDefault);
            return handleDefaultSelection(nextSelectedDestinations);
        });
    };

    const removeDestination = (destination: TravelFormDestinationResult) => {
        setSelectedDestinations((prev) => {
            const toRemoveDestinationIndex = prev.findIndex((_destination) => _destination.title === destination.title);
            const nextSelectedDestinations = prev.filter((_destination, index) => toRemoveDestinationIndex !== index);
            return handleDefaultSelection(nextSelectedDestinations);
        });
    };

    const getDestinationListString = (destinations: TravelFormDestinationResult[] = selectedDestinations) => {
        return destinations
            .filter((destination) => !destination.isDefault)
            .map((destination) => destination.name)
            .join(',');
    };

    const hasSelectionChanged = React.useCallback(
        (selection: TravelFormDestinationResult[]) => {
            const selectedDestionationsString = initialDestinationFilter?.selectedItems
                .map((destination) => destination.name)
                .sort()
                .join(',');

            const compareSelection = selection
                .map((item) => item.name)
                .sort()
                .join(',');

            return selectedDestionationsString !== compareSelection;
        },
        [initialDestinationFilter?.selectedItems],
    );

    React.useEffect(() => {
        if (!defaultDestination && isReady) {
            const destinationFilterResultSet = data?.attributes?.filters.destination?.resultSet;
            const defaultDestination = destinationFilterResultSet
                ?.find((destination) => destination.type === 'reset')
                ?.results.find((result) => result.isDefault);
            if (defaultDestination) {
                setDefaultDestination(defaultDestination);
            }
        }
    }, [data?.attributes?.filters.destination?.resultSet, defaultDestination, isReady]);

    // since we load travelForm overlays before TravelFormDataV1 is fully loaded, we have to manage certain states manually
    React.useEffect(() => {
        if (!isReady) {
            setTargetComponentIri(destinationRequestTarget);
        }
    }, [destinationRequestTarget, isReady, selectedItems]);

    return {
        addDestination,
        getDestinationListString,
        hasSelectionChanged,
        initialDestinationFilter,
        isLoading,
        onDestinationResultClick,
        removeDestination,
        resetOverlay,
        resetSearch,
        result: data?.attributes?.filters.destination,
        searchDestination,
        searchQuery,
        selectedDestinations,
        wasActivelyUsed,
    };
}

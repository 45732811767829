/* eslint-disable no-inline-styles/no-inline-styles */
import type {
    TravelFormDestinationResult,
    TravelFormDestinationResultSetType,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CheckBox from '@/core/components/check-box/check-box';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import {
    useActiveKeyboardItemName,
    useActiveKeyboardNavigationContainerIdAtom,
} from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';
import { HtmlTextWrapper } from '@/core/features';
import color from '@/core/features/styles/color';

type TravelFormDestinationResultListDesktopProps = {
    isFirstResultSet: boolean;
    isHoveringAnyDestinationResult: boolean;
    onResultClick: (
        event: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
        destination: TravelFormDestinationResult,
        enableNextInputOverlay?: boolean,
    ) => void;
    resultSet: TravelFormDestinationResultSetType;
    searchQuery: string;
    selectedDestinationsByName: string[];
    setIsHoveringAnyDestinationResult: (isHoveringAnyDestinationResult: boolean) => void;
};

export default function TravelFormDestinationResultSetDesktop({
    isFirstResultSet,
    isHoveringAnyDestinationResult,
    onResultClick,
    resultSet,
    searchQuery,
    selectedDestinationsByName,
    setIsHoveringAnyDestinationResult,
}: TravelFormDestinationResultListDesktopProps) {
    const destinations = resultSet.results;
    const activeKeyboardItemName = useActiveKeyboardItemName();
    const activeKeyboardNavigationContainerId = useActiveKeyboardNavigationContainerIdAtom();

    return (
        <div className={'width-100 font-size-16 bg-white'}>
            <style jsx>{`
                :global(.currentNavigationInput-mouse) .resultItem:hover {
                    background-color: ${color('foam')};
                }
            `}</style>
            {resultSet.title && (
                <div className={'padding-bottom-10 padding-left-20 padding-right-20'}>{resultSet.title}</div>
            )}
            <div>
                {destinations.map((destination, index) => {
                    const isKeyboardNavigationActive =
                        activeKeyboardNavigationContainerId === 'travel-form-destination-overlay-container-desktop';

                    const isItemSelected = selectedDestinationsByName.includes(destination.name);

                    const isDestinationActiveKeyboardItem =
                        activeKeyboardItemName === destination.name && isKeyboardNavigationActive;

                    const hasHighlightedBackground =
                        isDestinationActiveKeyboardItem ||
                        (index === 0 &&
                            isFirstResultSet &&
                            !isKeyboardNavigationActive &&
                            !isHoveringAnyDestinationResult &&
                            searchQuery);

                    return (
                        <KeyboardNavigationItemDesktop
                            key={`${destination.name}-${index}`}
                            name={destination.name}
                            onSelect={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                onResultClick(event, destination, true)
                            }
                        >
                            <div
                                className={`resultItem grid padding-20 ${!destination.isDefault ? 'padding-top-10 padding-bottom-10' : ''} align-center pointer ${hasHighlightedBackground ? 'bg-color-foam' : ''}`}
                                data-qa-id={'qa-travel-form-result-item-desktop'}
                                onClick={(event) => onResultClick(event, destination)}
                                onMouseEnter={() => setIsHoveringAnyDestinationResult(true)}
                                onMouseLeave={() => setIsHoveringAnyDestinationResult(false)}
                                style={{ gridTemplateColumns: '1fr auto', minHeight: 71 }}
                            >
                                <div className={'flex-column gap-5'}>
                                    {/* [bc-25] this is a progressive enhancement, delete fallbacks after api sends new fields */}
                                    <HtmlTextWrapper htmlText={destination.htmlTitle ?? destination.title} />
                                    <HtmlTextWrapper
                                        className={'font-size-14 text-dusty-gray'}
                                        htmlText={destination.htmlSubTitle ?? destination.subTitle}
                                    />
                                </div>
                                {resultSet.type === 'multiple' && !destination.isDefault ? (
                                    <CheckBox
                                        isSelected={isItemSelected}
                                        onClick={(event) => onResultClick(event, destination)}
                                    />
                                ) : (
                                    destination.isDefault && (
                                        <div
                                            className={'font-size-14 text-endeavour'}
                                            onClick={(event) => onResultClick(event, destination)}
                                        >
                                            wählen
                                        </div>
                                    )
                                )}
                            </div>
                        </KeyboardNavigationItemDesktop>
                    );
                })}
            </div>
        </div>
    );
}

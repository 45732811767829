import type { TravelFormDataV1_jsonld_page_read } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { ApplyTravelFormFilter } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import useDynamicPageComponentByType from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-type';
import { useDynamicPageUrl } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { useClientUrl } from '@/core/features/app/app-atoms';
import useAppEvents from '@/core/features/app/use-app-events';
import { useScrollToTarget } from '@/core/features/link/use-scroll-to-target';
import { useRouterLink } from '@/core/features/router/router-link';
import { getSearchParameterByName, hasSearchParamChanged } from '@/core/utils/url';
import { getRoomAllocationAsString } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import {
    useIsTravelFormNavigatingAtom,
    useSetHasTravelFormChanged,
    useSetIsTravelFormLoading,
} from '@/features/travel-form/travel-form-state';

export type TravelFormFilterValue = { end: string; start: string } | null | string;

type UseTravelFormDataV1Props = {
    handlePathReset: boolean;
    onSubmitted?: () => void;
};
export default function useTravelFormDataV1({ handlePathReset = true, onSubmitted }: UseTravelFormDataV1Props) {
    const dynamicPageUrl = useDynamicPageUrl();
    const setHasTravelFormChanged = useSetHasTravelFormChanged();
    const initialData = useDynamicPageComponentByType<TravelFormDataV1_jsonld_page_read>('TravelFormDataV1');
    const hasNoInitialData = initialData?.attributes === null;

    const [targetComponentIri, setTargetComponentIri] = React.useState<null | string>(
        hasNoInitialData ? (initialData?.['@id'] ?? null) : null,
    );

    const enabled = hasNoInitialData || initialData?.['@id'] !== targetComponentIri;

    const { navigate } = useRouterLink();
    const setIsTravelFormLoading = useSetIsTravelFormLoading();

    const appEvents = useAppEvents();
    const [clientUrl] = useClientUrl();

    const { data, isLoading } = useComponentDataByIri<TravelFormDataV1_jsonld_page_read>(targetComponentIri, {
        config: { enabled },
        queryName: 'travelFormDataV1',
    });

    const componentData = data ?? initialData;

    React.useEffect(() => {
        setIsTravelFormLoading(isLoading);
    }, [isLoading, setIsTravelFormLoading]);

    // Resets component to initial state after a route change. Not necessary for mobile travelFormCta since it mounts/unmounts during use
    React.useEffect(() => {
        if (!handlePathReset) {
            return;
        }

        // When no initial dynamic page data is provided, reset to first component request
        if (hasNoInitialData) {
            setTargetComponentIri(initialData?.['@id'] ?? null);
            return;
        }

        // Fallback to initial data
        setTargetComponentIri(null);
    }, [
        dynamicPageUrl, // only used to trigger rerender once dynamic page is initialized
        handlePathReset,
        hasNoInitialData,
        initialData,
    ]);

    const filters = componentData?.attributes?.filters;
    const destinationFilterName = filters?.destination?.name;
    const roomAllocationFilterName = filters?.roomAllocation?.name;

    const { scrollTo: scrollToTargetComponent } = useScrollToTarget(
        initialData?.attributes?.cta.scrollTo?.scrollToTarget ?? null,
    );

    const applyFilter = ({ filterName, filterValue, requestTargetOverride }: ApplyTravelFormFilter) => {
        const targetIri = requestTargetOverride ?? componentData?.['@id'];

        if (!targetIri) {
            return;
        }

        if (typeof filterName === 'string' && (typeof filterValue === 'string' || filterValue === null)) {
            const currentFilterValues =
                getSearchParameterByName(clientUrl, filterName)?.split(',').sort().join(',') ?? '';
            const initialSelectedRoomsAsString = getRoomAllocationAsString(
                initialData?.attributes?.filters?.roomAllocation?.configuration.preSelectedRooms,
            );

            // Filter changed
            if (
                currentFilterValues !== (filterValue ?? '').split(',').sort().join(',') &&
                filterName !== destinationFilterName && // Ignore Destination Filter changes because they get directly redirected
                !(filterName === roomAllocationFilterName && filterValue === initialSelectedRoomsAsString) // Change to Initial Room Allocation should not suggest a filter change
            ) {
                setHasTravelFormChanged(true);
            }

            setFilter(targetIri, { [filterName]: filterValue });

            if (destinationFilterName === filterName) {
                appEvents.emit('destination_change');
            }

            return;
        }

        if (typeof filterName === 'object' && (typeof filterValue === 'object' || filterValue === null)) {
            if (!filterValue) {
                return;
            }

            const currentDateFilterValueStart = getSearchParameterByName(clientUrl, filterName.start);
            const currentDateFilterValueEnd = getSearchParameterByName(clientUrl, filterName.end);

            // Date Filter changed
            if (currentDateFilterValueEnd !== filterValue.end || currentDateFilterValueStart !== filterValue.start) {
                setHasTravelFormChanged(true);
            }

            setFilter(targetIri, { end: filterValue.end, start: filterValue.start });
            return;
        }
    };

    const setFilter = (targetIri: string, userQuery: Record<string, TravelFormFilterValue>) => {
        const updatedUrl = updateComponentIriParam('userQuery', targetIri, userQuery);
        setTargetComponentIri(updatedUrl.pathname + updatedUrl.search);
    };

    const [pendingSubmitNavigation, setPendingSubmitNavigation] = useIsTravelFormNavigatingAtom();
    const submitNavigationTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    //This hook handles a pending submit navigation
    React.useEffect(() => {
        const linkUrl = data?.attributes?.cta.linkUrl ?? initialData?.attributes?.cta.linkUrl;

        if (isLoading || !pendingSubmitNavigation || !linkUrl) {
            return;
        }

        if (
            typeof destinationFilterName === 'string' &&
            hasSearchParamChanged(clientUrl, linkUrl, destinationFilterName)
        ) {
            appEvents.emit('destination_change');
        }

        navigate(linkUrl);
        setPendingSubmitNavigation(false);
        onSubmitted?.();

        const clearSubmitNavigationTimeout = () => {
            if (submitNavigationTimeoutRef.current) {
                clearTimeout(submitNavigationTimeoutRef.current);
                submitNavigationTimeoutRef.current = null;
            }
        };

        clearSubmitNavigationTimeout();

        return clearSubmitNavigationTimeout;
    }, [
        appEvents,
        clientUrl,
        data?.attributes?.cta.linkUrl,
        destinationFilterName,
        initialData?.attributes?.cta.linkUrl,
        isLoading,
        navigate,
        onSubmitted,
        pendingSubmitNavigation,
        setPendingSubmitNavigation,
    ]);

    const submitForm = (linkUrl: string) => {
        if (pendingSubmitNavigation) {
            return;
        }

        setHasTravelFormChanged(false);
        if (linkUrl === clientUrl) {
            onSubmitted?.();
            scrollToTargetComponent();
            return;
        }

        // After a submit we need to wait for the new linkUrl before navigating, else we navigate to the old one
        setPendingSubmitNavigation(true);

        submitNavigationTimeoutRef.current = setTimeout(() => {
            setPendingSubmitNavigation(false);
            submitNavigationTimeoutRef.current = null;
        }, 15000);
    };

    return {
        activeFilters: componentData?.attributes?.activeFilters ?? [],
        applyFilter,
        initialCtaLinkUrl: initialData?.attributes?.cta.linkUrl,
        isLoading,
        requestTargets: {
            activityRequestTarget: initialData?.meta.activityRequestTarget,
            airportsRequestTarget: componentData?.meta.airportsRequestTarget,
            dateRequestTarget: componentData?.meta.dateRequestTarget,
            destinationChangeRequestTarget: componentData?.meta.destinationChangeRequestTarget,
            destinationRequestTarget: componentData?.meta.destinationRequestTarget,
        },
        scrollTo: initialData?.attributes?.cta.scrollTo,
        submitForm,
        travelForm: componentData?.attributes ?? null,
    };
}

import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import { useRefRerender } from '@/core/features/render/use-ref-rerender';

export default function useTravelForm({
    activeFilters,
    onSubmit,
    onTravelFormLoaded,
    openOverlay,
}: {
    activeFilters: TravelFormDataV1Attributes['activeFilters'];
    onSubmit: () => void;
    onTravelFormLoaded: () => void;
    openOverlay: (overlayName: TravelFormOverlayName) => void;
}) {
    const [fieldsWithError, setFieldsWithError] = React.useState<TravelFormFilterName[]>([]);
    const expandedWrapperRef = React.useRef<HTMLDivElement | null>(null);
    useRefRerender(expandedWrapperRef);

    const addErrorFields = (filterNames: TravelFormFilterName[]) => {
        if (!filterNames.length || filterNames.some((filterName) => fieldsWithError.includes(filterName))) {
            return;
        }
        setFieldsWithError((prev) => [...prev, ...filterNames]);
    };

    const removeErrorFields = (filterNames: TravelFormFilterName[]) => {
        if (!filterNames.length) {
            return;
        }
        setFieldsWithError((prev) => prev.filter((field) => filterNames.includes(field)));
    };

    const onInputClickHandler = (overlayName: TravelFormOverlayName, dependsOn: TravelFormFilterName[] = []) => {
        if (!dependsOn.length) {
            openOverlay(overlayName);
            return;
        }

        const errorFields = dependsOn.filter(
            (filterName) => typeof filterName === 'string' && !activeFilters.includes(filterName),
        );

        if (!!errorFields.length) {
            addErrorFields(errorFields);
            return;
        }

        removeErrorFields(dependsOn);
        openOverlay(overlayName);
    };

    const isInputError = (activityName: TravelFormFilterName | null) => {
        return (
            typeof activityName === 'string' &&
            fieldsWithError.includes(activityName) &&
            !activeFilters.includes(activityName)
        );
    };

    const getInputError = (
        activityName: TravelFormFilterName | null,
        error: {
            iconUrl: string;
            text: string;
        } | null,
    ) => {
        if (
            typeof activityName === 'string' &&
            fieldsWithError.includes(activityName) &&
            !activeFilters.includes(activityName)
        ) {
            return error;
        }
        return null;
    };

    const onSubmitHandler = (dependsOn: TravelFormFilterName[]) => {
        if (!dependsOn.length) {
            onSubmit();
            return;
        }

        const errorFields = dependsOn.filter(
            (filterName) => typeof filterName === 'string' && !activeFilters.includes(filterName),
        );

        if (!!errorFields.length) {
            addErrorFields(errorFields);
            return;
        }

        removeErrorFields(dependsOn);
        onSubmit();
    };

    const isInputDisabled = (dependsOn: TravelFormFilterName[]) => {
        if (!dependsOn.length) {
            return false;
        }
        return !activeFilters.some((field) => dependsOn.includes(field));
    };

    React.useEffect(() => {
        onTravelFormLoaded();
    }, [onTravelFormLoaded]);

    return {
        expandedWrapperRef,
        getInputError,
        isInputDisabled,
        isInputError,
        onInputClickHandler,
        onSubmitHandler,
    };
}
